<template>
  <!-- eslint-disable -->
  <div class="sidebar-wrapper">
    <div v-if="isShowing ? backdrop : ''" class="backdrop" ref="backdrop" @click="hide"></div>
    <transition name="slide">
      <div v-if="isShowing" class="sidebar shadow bg-white text-dark">
        <header class="sidebar-header">
          <button type="button" class="close text-dark" @click="reset(false)">
          <span class="material-icons-outlined">
            close
          </span>
          </button>
          <strong id="sidebar-title">Upload</strong>
        </header>
        <div class="sidebar-body">
          <div class="progress" v-if="progress > 0">
            <div class="progress-bar" :style="`width: ${progress}%`" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div v-if="hasFile">
            <div class="crop-options">
              <button class="btn btn-crop" v-if="startCrop" @click="preview = true, cropImage()"><span class="material-symbols-outlined">crop</span></button>
              <button class="btn btn-crop-close" @click="reset(true)"><span class="material-symbols-outlined">close</span></button>
            </div>
          </div>
          <div v-if="hasFile && startCrop">
            <Cropper
              ref="cropper"
              class="upload-example-cropper"
              :src="imgUrl"
              :auto-zoom="true"
              :min-height="minHeight"
	            :min-width="minWidth"
              :max-height="maxHeight"
	            :max-width="maxWidth"
              :stencil-component="type === 'circle' ? $options.components.Stencil : 'rectangle-stencil'"
            />
            <div v-if="preview && type != 'circle'">
              <div class="preview" v-if="imgFinal !== null" :class="type">
                <img :src="imagePreview"/>
              </div>
            </div>
          </div>
          <div class="dropz" id="dropz" ref="dropz" v-if="!startCrop">
            <input
              type="file"
              ref="fileInput"
              :accept="accept"
              @change="onFilePicked"/>
            <p class="zone-text" v-if="!hasFile">
              <span class="material-symbols-outlined block">upload</span>
              Arraste aqui ou clique <br>para fazer o upload
            </p>
          </div>
          <footer class="sidebar-footer">
            <ButtonComponent @click="upload">Salvar</ButtonComponent>
          </footer>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from '@/plugins/axios';
import Stencil from '@/components/ui/UploadComponent/Stencil.vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

const bucketApi = axios('buckets3');

export default {
  name: 'UploadComponent',
  props: {
    isAvatar: Boolean,
    type: String,
    maxFileSize: Number,
    accept: String,
    minWidth: Number,
    maxHeight: Number,
    maxWidth: Number,
    minHeight: Number,
  },
  components: {
    ButtonComponent,
    Cropper,
    /* eslint-disable */
    Stencil,
    /* eslint-enable */
  },
  data() {
    return {
      imgFinal: null,
      imgName: null,
      imgExtension: null,
      imgUrl: '',
      isShowing: false,
      hasFile: false,
      startCrop: false,
      backdrop: false,
      progress: 0,
      preview: false,
      maxSize: 0,
      imagePreview: '',
    };
  },
  watch: {
    maxFileSize: (newVal) => {
      this.maxSize = newVal;
    },
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    user() {
      return this.$store?.state?.auth?.user;
    },
  },
  mounted() {
    this.$root.$on('loadPreview', () => {
      // this.cropImage();
    });
    this.$root.$on('upload.callPicker', () => {
      this.callPicker();
    });
  },
  beforeDestroy() {
    // this.$root.$off('loadPreview');
  },
  methods: {
    reset(isShowing) {
      this.imgFinal = null;
      this.imgName = null;
      this.imgExtension = null;
      this.imgUrl = '';
      this.isShowing = isShowing;
      this.hasFile = false;
      this.startCrop = false;
      this.progress = 0;
      this.preview = false;
      this.file = {};
      if (document.querySelector('.drop-img')) {
        document.querySelector('.drop-img').remove();
      }
      this.$refs.fileInput.value = null;
    },
    toggle() {
      this.isShowing = !this.isShowing;
    },
    show() {
      this.isShowing = true;
    },
    hide() {
      this.isShowing = false;
    },
    callPicker() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const re = /(?:\.([^.]+))?$/;
      const files = event.target.files;
      const filename = files[0].name;
      this.file = files[0];
      this.imgName = filename;
      this.imgExtension = re.exec(filename)[1];
      console.log('this.accept', this.accept);
      console.log('files[0].type', files[0].type);
      const filesize = ((files[0].size / 1024) / 1024).toFixed(4); // MB
      if (filesize > this.maxFileSize) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: `O arquivo é muito grande, o tamanho máximo é de ${this.maxFileSize}MB.`,
          type: 'danger',
        });
        this.reset(true);
        return;
      }

      if (!this.accept.includes(files[0].type.split('/')[0])) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Formato não permitido.',
          type: 'danger',
        });
        this.reset(true);
        return;
      }

      if (document.querySelector('.drop-img')) {
        document.querySelector('.drop-img').remove();
      }
      const dropImg = document.createElement('div');
      dropImg.classList.add('drop-img');
      dropImg.classList.add('file-unic');

      const span = document.createElement('span');
      span.classList.add('material-icons-outlined');

      if (files[0].type.includes('image')) {
        span.innerHTML = 'image';
      } else if (files[0].type.includes('video')) {
        span.innerHTML = 'play_circle';
      } else if (files[0].type.includes('audio')) {
        span.innerHTML = 'music_note';
      } else {
        span.innerHTML = 'content_copy';
      }

      const h3 = document.createElement('h3');
      h3.classList.add('h3-type');
      h3.innerHTML = files[0].type;

      dropImg.append(span);
      dropImg.append(h3);
      this.$refs.dropz.append(dropImg);

      console.log('Img name: ', filename);
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      this.imgUrl = URL.createObjectURL(this.image);
      this.$emit('onFilePicked');
      this.hasFile = true;
      if (files[0].type.includes('image')) {
        this.startCrop = true;
        setTimeout(() => {
          this.cropImage();
        }, 2000);
      }
    },
    cropImage() {
      const result = this.$refs.cropper.getResult();
      this.imgFinal = result;
      console.log(result);
      this.imagePreview = this.imgFinal.canvas.toDataURL('image/jpeg');
    },
    upload() {
      this.progress = 0;
      if (this.image.type.includes('image') && this.isAvatar) {
        if (this.isAvatar) {
          this.uploadImageAvatar();
        } else {
          this.uploadImage();
        }
      } else {
        this.uploadFile();
      }
    },
    uploadImageAvatar() {
      this.isSending = true;
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const imageBase64 = this.imgFinal.canvas.toDataURL('image/jpeg');
        console.log(imageBase64);
        const formData = new FormData();
        formData.append('base64', imageBase64);
        formData.append('account_id', this.account.id);
        formData.append('user_id', this.user.id);
        const config = {
          headers: { 'Content-Type': 'multipart/form-data', 'X-API-SECRET': 'Odyg1JD2UgJKsCojwwDppA7PdYSasIAY' },
          onUploadProgress: (progressEvent) => {
            this.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
          },
        };
        const result = bucketApi.post('/avatar', formData, config).then((response) => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Upload realizado com sucesso',
            type: 'success',
          });
          this.progress = 0;
          this.callComplete(response);
          this.reset();
        },
        (error) => {
          this.content = error;
          this.progress = 0;
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: error.response.data.message,
            type: 'danger',
          });
        });
      }
      this.isSending = false;
      this.$forceUpdate();
    },
    callComplete(data) {
      this.$emit('uploaded', data);
    },
    uploadFile() {
      this.isSending = true;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('account_id', this.account.id);
      formData.append('user_id', this.user.id);
      const config = {
        headers: { 'Content-Type': 'multipart/form-data', 'X-API-SECRET': 'Odyg1JD2UgJKsCojwwDppA7PdYSasIAY' },
        onUploadProgress: (progressEvent) => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };
      const result = bucketApi.post('', formData, config).then((response) => {
        this.$toast.show({
          title: this.$t('generic-str.success'),
          content: 'Upload realizado com sucesso',
          type: 'success',
        });
        this.progress = 0;
        this.callComplete(response);
        this.reset();
      },
      (error) => {
        this.content = error;
        this.progress = 0;
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: error.response.data.message,
          type: 'danger',
        });
      });
      this.isSending = false;
      this.$forceUpdate();
    },
    uploadImage() {
      this.isSending = true;
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const imageBase64 = this.imgFinal.canvas.toDataURL('image/jpeg');
        console.log(imageBase64);
        const formData = new FormData();
        formData.append('base64', imageBase64);
        formData.append('account_id', this.account.id);
        formData.append('user_id', this.user.id);
        const config = {
          headers: { 'Content-Type': 'multipart/form-data', 'X-API-SECRET': 'Odyg1JD2UgJKsCojwwDppA7PdYSasIAY' },
          onUploadProgress: (progressEvent) => {
            this.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
          },
        };
        const result = bucketApi.post('', formData, config).then((response) => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Upload realizado com sucesso',
            type: 'success',
          });
          this.progress = 0;
          this.callComplete(response);
          this.reset();
        },
        (error) => {
          this.content = error;
          this.progress = 0;
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: error.response.data.message,
            type: 'danger',
          });
        });
      }
      this.isSending = false;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
  img {
    max-height: 280px;
  }
  &.circle {
    transform: scale(.5);
  }
  &.circle img {
    border-radius: 50%;
  }
}
.crop-options {
  display: flex;
  width: 100%;
  margin-bottom: .5rem;
  .btn-crop {
    padding: 0;
    background-color: var(--clr-yup-purple-hover-transparent);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 1px 1px var(--clr-yup-purple);
    margin-left: 0px;
    margin-right: 10px;
    span {
      color: var(--clr-yup-purple-hover);
    }
  }
}
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: visible;
  z-index: 1035;

  .sidebar {
    position: fixed;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 525px;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    outline: 0;
    left: auto;
    right: 0;
    overflow: auto;

    .sidebar-header {
      font-size: 1.5rem;
      padding: .5rem 40px;
      align-items: center;
    }

    .sidebar-body {
      padding-left: 40px;
      padding-right: 40px;
      height: 100%;
    }
    .sidebar-footer {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-top: 20px;
      border-top: 1px solid #E4E7EC;
    }
  }
  .backdrop {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: #00000085;
  }
}

.slide-enter-active {
  transition: all .3s ease;
}

.slide-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-enter, .slide-leave-to {
  transform: translateX(525px);
}
.dropz {
  background: #fafafa;
  border-radius: 5px;
  border: 1px dashed rgb(183, 188, 192);
  border-image: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 200px;
  padding-bottom: 60px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  margin-top: 30px;
}

.dropz input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.dropz p {
  text-align: center;
  padding-top: 15%;
  font-size: 20px;
  color: #ccc;
}
.block {
  display: block;
  font-size: 30px;
}
.progress {
  margin-bottom: 10px;
}
.crop-options .btn-crop-close {
  padding: 0;
  background-color: #ff025828;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 1px 1px #f41212;
  box-shadow: 0px 0px 1px 1px #f90303;
  margin-left: 0px;
  margin-right: 10px;
}
.btn-crop-close span {
    color: #ff6767;
}
</style>
